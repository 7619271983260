.product-layout {
  .product-thumb {
    padding: 0;
    border: $thin $border;
    background-color: $white;
    .caption {
      padding: 15px 15px 0; }
    .image {
      position: relative;
      a {
        text-decoration: none;
        &:hover {
          &:before,
          &:after {
            opacity: 1 !important; } } }
      a:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(242,97,1,.7);
        opacity: 0;
        @include transition-duration(.3s); }
      a:after {
        content: '\f0c1';
        font: $font-icon;
        color: $white !important;
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        @include transition-duration(.3s);
        @include transform(translateX(-50%) translateY(-50%)); } } }
  .caption {
    h4 {
      font-size: $text-16;
      margin-bottom: rem(15); } }
  .list-chars {
    margin-bottom: rem(15);
    &-row {
      font-size: $text-12;
      color: $text-light;
      border-bottom: 1px dotted $border;
      padding-bottom: 3px;
      span {
        font-weight: 500;
        color: $text-dark;
        float: right; } } }
  .price-new,
  .price {
    display: inline-block;
    margin-right: 5px;
    color: $theme-hov;
    font-weight: 500;
    font-size: $text-20; }
  .price-old {
    display: inline-block;
    color: $text-light;
    vertical-align: top;
    text-decoration: line-through;
    font-weight: 400;
    font-size: $text-14; }
  .button-group {
    padding: 15px;
    border-top: $thin $border;
    button {
      &:nth-child(2),
      &:nth-child(3) {
        float: right; }
      &:hover {
        color: $theme; } } } }
