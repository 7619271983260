ul.ascp_horizont {
  margin-bottom: 30px;
  li {
    .sc-blog_result,
    .form-group {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0; }
    .sc-blog_result {
      span {
        font-weight: 600;
        margin-right: 10px; } }
    .sc-blog_infres {
      display: inline-block;
      margin-top: 5px;
      font-size: $text-12;
      color: $text-light;
      font-style: italic; }
    .form-control {
      border-radius: 0; } } }
.news-list {
  .image.blog-image {
    padding: 5px;
    border: $thin $grey;
    margin: 0 20px 10px 0;
    @include transition-duration(.3s);
    &:hover {
      background-color: $grey; } }
  .ascp-list-title {
    margin-bottom: 20px;
    font-size: $text-24;
    font-weight: 500;
    a {
      color: $text-dark;
      &:hover {
        color: $theme-hov;
        text-decoration: none; } } }
  .seocms_further {
    display: inline-block;
    margin-top: 20px;
    border-radius: 0;
    background-color: $theme;
    border-color: $theme;
    &:hover {
      background-color: $theme-hov; } }
  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    border-radius: 0; }
  .pagination>.active>a,
  .pagination>.active>a:focus,
  .pagination>.active>a:hover,
  .pagination>.active>span,
  .pagination>.active>span:focus,
  .pagination>.active>span:hover {
    background-color: $theme;
    border-color: $theme; }
  .pagination>li>a,
  .pagination>li>a:focus,
  .pagination>li>a:hover,
  .pagination>li>span:focus,
  .pagination>li>span:hover {
    color: $theme; }
  .records_more.btn-primary {
    background-color: $theme;
    border-radius: 0;
    float: none;
    border-color: $theme;
    margin-left: -5px;
    &:hover {
      background-color: $theme-hov; } }
  .records_more.btn-primary {
    margin: 10px 5px; }
  #records_more {
    text-align: center; } }

.news-item {
  img {
    padding: 5px;
    border: $thin $grey;
    margin: 10px; }
  p {
    font-size: $text-14;
    line-height: 1.5; }
  table {
    width: 100%;
    margin: 30px 0;
    td,
    th {
      padding: 10px;
      border: $thin $border;
      font-size: $text-14; }
    th {
      font-weight: 500;
      color: $text-black; } }
  .tags {
    margin: 15px 0; } }

.news-widget-home {
  .owl-wrapper-outer {
    box-shadow: none;
    .content-records {
      padding: 15px; } }
  .blog-data-record {
    font-weight: normal;
    font-size: $text-12;
    color: $text-light; }
  .ascp-list-title-widget {
    font-size: $text-18;
    line-height: 1.3;
    margin: 10px 0; }
  .seocms_further,
  .sc-button-all {
    background-color: $theme;
    border-color: $theme;
    border-radius: 0;
    display: inline-block;
    margin-top: 15px;
    &:hover {
      background-color: $theme-hov; } }
  .owl-controls {
    display: none !important; } }

.blog-next-prev {
  a {
    &:hover {
      text-decoration: none; }
    &:last-child {
      float: right; } } }

.blog-small-record {
  .blog-data-record {
    margin-bottom: 20px; } }
