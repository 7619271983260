//category wall
.sc-main {
  margin: rem(40) 0; }
.sc-main img {
  width: 100%; }
.showcase .item-info a:hover,
.showcase .item-wrapper:hover .item-heading a,
.showcase .item-wrapper.active .item-heading a {
  color: $theme-hov !important;
  text-decoration: none; }

//owl
.tab-car-wrapper {
  margin: rem(40) 0;
  padding: 30px 0;
  background-color: $super-grey;
  .owl-carousel {
    background: none; }
  .nav.nav-tabs.nav-owl {
    border: none;
    margin-bottom: 25px; }
  .nav-tabs>li {
    margin-right: 25px; }
  .nav-tabs>li>a {
    padding: 0;
    background: none !important;
    color: $text-black;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $text-18;
    border-bottom: 2px dotted $black;
    &:hover {
      border-color: transparent; } }
  .nav-tabs>li.active>a {
    color: $theme;
    border-color: transparent; }
  .owl-carousel {
    .owl-buttons {
      div {
        opacity: 1 !important; }
      i {
        font-size: 14px;
        margin: 0;
        color: $white; } } }
  .product-layout .owl-carousel .owl-buttons {
    .owl-prev,
    .owl-next {
      top: -50px;
      background-color: $theme;
      height: 25px;
      width: 25px;
      padding: 5px;
      text-align: center; }
    .owl-next {
      right: 30px; }
    .owl-prev {
      right: 60px !important;
      left: auto; } } }

//megafilter
.mfilter-box {
  margin: 30px 0 40px;
  .box-heading {
    font-size: 18px; } }
.mfilter-content {
  border-radius: 0; }
.mfilter-opts-container {
  padding: 15px 10px; }
#mfilter-opts-price-min,
#mfilter-opts-price-max {
  min-width: 70px;
  width: 70px; }
#mfilter-opts-price-max,
.mfilter-opts-slider-max {
  float: right;
  margin-bottom: 10px; }

#yomenu-1 {
  .box-heading {
    font-size: $text-18; }
  .am-toggle {
    padding-left: 0; }
  ul.yo-am li a:hover,
  ul.yo-am li.am-item-open > a {
    color: $theme; } }
