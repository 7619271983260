///Global
$min-window-width: 320px;
$basis-font-size: 16px;
$default-line-height: 1.33;
$medium-font-size: 0.875rem;

///Media queries
$xs-screen: 320px;
$s-screen: 480px;
$m-screen: 768px;
$l-screen: 990px;
$xl-screen: 1200px;
$xxl-screen: 1440px;

//Fons
$font-main: 'Fira Sans', sans-serif;
$font-title: 'Fira Sans Condensed', sans-serif;
$font-icon: normal normal normal 14px/1 FontAwesome;

///Text size
$text-10: 0.625rem;
$text-12: 0.75rem;
$text-13: 0.8125rem;
$text-14: 0.875rem;
$text-16: 1rem;
$text-18: 1.125rem;
$text-20: 1.25rem;
$text-22: 1.375rem;
$text-24: 1.5rem;
$text-26: 1.625rem;
$text-28: 1.75rem;
$text-30: 1.875rem;
$text-32: 2rem;
$text-34: 2.125rem;
$text-36: 2.25rem;
$text-38: 2.375rem;
$text-40: 2.5rem;
$text-48: 3rem;
$text-56: 3.5rem;
$text-60: 3.75rem;
$text-72: 4.5rem;

///Size
$thin: 1px solid;

