html {
  font-size: $basis-font-size; }
body {
  font-size: $medium-font-size;
  font-family: $font-main;
  line-height: $default-line-height;
  font-weight: 400;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-title;
    font-style: normal;
    margin-bottom: rem(20); }
  a {
    color: $theme;
    text-decoration: none;
    outline: none !important;
    @include transition-duration(.3s);
    &:hover,
    &:active {
      color: $theme-hov; } } }
