.articles-grid {
  .blog-image {
    float: none;
    display: block;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
      padding: 5px;
      border: $thin $light-grey;
      @include transition-duration(.3s);
      &:hover {
        border-color: $theme; } } }
  .ascp-list-title {
    font-size: $text-18;
    margin-bottom: 10px; }
  .description.record_description {
    p {
      font-size: $text-14;
      color: $text-light; } }
  .record_columns .column_width_ {
    padding: 0;
    margin: 0 10px 30px; }
  .description.record_description {
    padding: 0 10px;
    font-size: $text-13; }
  .records_more.btn-primary,
  .btn-primary.seocms_further {
    background-color: $theme;
    border-radius: 0;
    float: none;
    border-color: $theme;
    margin-left: -5px;
    &:hover {
      background-color: $theme-hov; } }
  .records_more.btn-primary {
    margin: 10px 5px; }
  #records_more {
    text-align: center; }
  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    border-radius: 0; }
  .pagination>.active>a,
  .pagination>.active>a:focus,
  .pagination>.active>a:hover,
  .pagination>.active>span,
  .pagination>.active>span:focus,
  .pagination>.active>span:hover {
    background-color: $theme;
    border-color: $theme; }
  .pagination>li>a,
  .pagination>li>a:focus,
  .pagination>li>a:hover,
  .pagination>li>span:focus,
  .pagination>li>span:hover {
    color: $theme; } }

.content-records.home-records {
  width: 31%;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 20px;
  .blog-image.sc-widget-image-block {
    display: block;
    float: none;
    img {
      width: 100%;
      height: auto;
      padding: 5px;
      border: 1px solid #bdbcbc;
      @include transition-duration(.3s);
      &:hover {
        border-color: $theme; } } }
  .news-widget-home.ascp-list-title-widget {
    display: block; }
  .news-widget-home.seocms_further {
    display: block;
    float: none; } }
