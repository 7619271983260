.search-page {
  .product-layout {
    margin-bottom: 30px; }
  select {
    border-radius: 0; }
  input[type="button"] {
    background-color: $theme;
    border-color: $theme;
    border-radius: 0;
    display: inline-block;
    margin-top: 15px;
    &:hover {
      background-color: $theme-hov; } } }
