.footer-row {
  margin-top: 50px;
  padding: 25px 0;
  background-color: $hard;
  h5 {
    color: $white;
    font-size: $text-18;
    margin-bottom: 20px; }
  .list-unstyled {
    li {
      margin-bottom: 5px; } }
  hr {
    border-color: $light-grey; }
  p {
    color: $white; } }

