.top {
  &-logo {
    padding: 20px 0 25px; }
  &-search {
    padding: 45px 0;
    input[type="text"] {
      font-size: $text-14; }
    .btn {
      border-color: $theme;
      background-color: $theme;
      color: $white;
      &:hover {
        background-color: $theme-hov; } }
    .smartsearch {
      top: 49px !important;
      border-radius: 0 !important; }
    .smartsearch-product {
      padding: 10px !important; } }
  &-cart {
    padding: 35px 0 30px;
    text-align: right;
    #wishlist-total {
      display: inline-block;
      border-right: $thin $border;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
      color: $text-dark;
      &:hover {
        text-decoration: none;
        color: $theme-hov; }
      i {
        font-size: 30px;
        display: block; }
      span {
        font-size: $text-12; } } } }

#cart {
  text-align: center;
  padding: 10px;
  i {
    font-size: 30px;
    display: block;
    color: $theme;
    @include transition-duration(.3s); }
  #cart-total {
    font-size: $text-12;
    color: $theme;
    @include transition-duration(.3s); }
  &:hover {
    i,
    #cart-total {
      color: $theme-hov; } }
  .btn {
    padding: 0;
    font-size: 1rem; } }

.form-control:focus {
  border-color: $theme;
  box-shadow: none; }

.top-menu-wrapper {
  border-top: $thin $border;
  border-bottom: $thin $border;
  margin-bottom: 15px;
  padding: 10px 0;
  #supermenu {
    background: none;
    margin: 0;
    border-radius: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    ul li div .withchild {
      width: 23%;
      margin-bottom: 20px;
      a.theparent {
        font-weight: 500;
        color: $text-dark;
        margin-bottom: 10px;
        &:hover {
          color: $theme-hov; } } }
    ul li div.bigdiv .linkoftopitem a {
      color: $white !important;
      text-decoration: none;
      padding: 6px;
      text-align: center;
      border: none;
      display: block;
      border-radius: 0;
      font-size: 12px;
      background-color: $theme;
      background-image: none !important;
      &:hover {
        background-color: $theme-hov; } }
    ul li a.tll {
      text-transform: uppercase;
      font-size: $text-16;
      padding: 15px 20px;
      text-shadow: none !important;
      color: $text-dark;
      font-weight: 500;
      border-right: $thin $border;
      &:hover {
        background-color: $theme-hov !important;
        border-color: $theme-hov;
        color: $white !important; } } } }

.top-menu-wrapper #supermenu.respsmall ul li a.tll {
  color: $white;
  font-weight: 400; }

#supermenu.respsmall ul li.tlli.sep {
  border-color: $white !important; }

#supermenu.respsmall {
  background-color: $theme;
  a.mobile-trigger {
    background: none !important;
    position: relative;
    text-transform: uppercase;
    &:after {
      content: '\f0c9';
      font-family: 'FontAwesome';
      color: $white;
      font-size: $text-18;
      position: absolute;
      right: 15px;
      top: 15px; } } }
