///Global
$theme: #F26101;
$grey: #DEDEDE;
$soul: #8AA8B0;
$hard: #313732;
$bg-white: #fff;
$border: #e5e5e5;
$black: #000;
$white: #fff;
$light-grey: #bdbcbc;
$super-grey: #f5f5f5;

///Hover
$theme-hov: #F24406;

///Fonts
$text-dark: #333;
$text-light: #777;
$text-black: $black;

///Header
$menu-bg: $border;
