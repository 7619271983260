* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:before,
  &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; } }
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
body {
  min-width: $min-window-width;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: $bg-white;
  color: $text-dark; }
fieldset {
  border: none !important; }

.center-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.float-line {
  float: none !important;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px; }

.titles {
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    height: 1px;
    background-color: $theme;
    margin-left: 15px;
    opacity: .6; } }

