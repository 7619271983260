.podcategory-grid {
  padding: 15px 10px 0;
  margin: 25px 0 15px;
  background-color: $super-grey;
  &-item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0 10px 15px;
    width: 100%;
    @include breakpoint(s) {
      width: 45%; }
    @include breakpoint(l) {
      width: 21%; }
    @include breakpoint(xl) {
      width: 22%; }
    a {
      display: block;
      font-size: $text-12;
      color: $text-dark;
      font-weight: 500;
      &:first-child {
        margin-bottom: 5px; } } } }

.cat-filter-line {
  background-color: $super-grey;
  padding: 10px;
  .btn-default:focus {
    outline: none;
    color: $theme;
    background-color: $white; }
  .control-label {
    padding-top: 8px; }
  .form-control {
    border-radius: 0;
    font-size: $text-12; } }

.category-page {
  .product-grid {
    .prod-lay-description {
      display: none; } }
  .product-layout {
    margin-bottom: 30px; }
  .product-list {
    .image {
      display: inline-block;
      float: left;
      margin-right: 20px; }
    .prod-lay-description {
      display: inline-block;
      font-size: $text-12;
      color: $text-light;
      margin: 10px 0; } } }

.compare-row {
  display: block;
  margin-bottom: 15px;
  font-weight: 500; }
