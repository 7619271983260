#biglist-menu {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none; } }
  .biglist-first {
    margin-top: 50px;
    & > li > a {
      display: none; } }
  .biglist-second {
    & > li {
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: $thin $light-grey; }
    & > li a {
      display: block; } }
  .biglist-third {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    & > li {
      display: inline-block;
      vertical-align: top;
      width: 30%;
      margin: 1%;
      border-bottom: $thin $border;
      padding-bottom: 3px;
      & > a {
        font-weight: 500;
        &:hover {
          text-decoration: none;
          color: $theme-hov; } } } }
  .biglist-fourth {
      margin: 3px 0 3px 5px;
      a {
        color: $text-dark; } } }

.biglist-img-block {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  margin-right: 20px;
  text-align: center;
  a {
    &:first-child {
      margin-bottom: 10px;
      img {
        @include transition-duration(.3s); }
      &:hover {
        img {
          opacity: .7; } } }
    &:last-child {
      text-transform: uppercase;
      font-weight: 500;
      font-size: $text-16;
      color: $text-dark;
      &:hover {
        color: $theme-hov;
        text-decoration: none; } } } }
