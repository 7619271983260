#{$all-text-inputs} {
  border: $thin $border;
  padding: 5px 10px;
  outline: none;
  border-radius: 0;
  box-shadow: none; }

button {
  border: none;
  background: none;
  outline: none;
  border-radius: 0 !important; }
