.top {
  background-color: $grey;
  &-phone {
    padding: 10px 0;
    &-text {
      font-weight: 500; } }
  &-menu {
    padding: 10px 0;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        a {
          color: $text-dark;
          font-size: $text-13;
          &:hover,
          &:active {
            color: $theme-hov; } } } } }
  &-account {
    padding: 10px 0;
    text-align: right;
    ul {
      padding: 0;
      margin: 0; }
    a {
      color: $text-dark;
      font-size: $text-13;
      &:hover,
      &:focus,
      &:active {
        color: $theme-hov;
        text-decoration: none; }
      i {
        color: $theme; } } } }
