.prod-page {
  .thumbnails {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      &:first-child {
        display: block; }
      &.image-additional {
        margin: 0 5px;
        .thumbnail {
          padding: 5px; } } }
    .thumbnail {
      display: inline-block;
      padding: 0;
      border-radius: 0;
      margin-bottom: 10px;
      &:hover,
      &:active,
      &:focus {
        border-color: $theme; } } }
  .social-row {
    margin: 10px; }
  h1 {
    font-size: $text-24; }
  .list-prod-info {
    &-item {
      font-weight: 500;
      color: $theme; } }
  .list-price {
    &-item {
      font-weight: 500;
      font-size: $text-28;
      color: $theme;
      display: inline-block; }
    &-special {
      color: $text-light;
      font-size: $text-16;
      display: inline-block;
      text-decoration: line-through;
      vertical-align: top; }
    &-points {
      color: $text-light;
      display: block;
      margin: 10px 0;
      span {
        font-weight: 600;
        color: $text-black; } }
    &-disc {
      border-top: $thin $border;
      border-bottom: $thin $border;
      margin: 15px 0;
      padding: 10px 0;
      p > span {
        font-weight: 600;
        color: $text-black; } } }
  .prod-btns {
    margin: 15px 0;
    padding-top: 10px;
    border-top: $thin $border;
    .btn {
      margin-top: 10px;
      border-color: $theme;
      background-color: $theme;
      &:hover {
        background-color: $theme-hov; } } }
  .rating {
    .fa {
      width: 1em;
      &:nth-child(5) {
        margin-right: 20px; } } }
  .prod-chars {
    .table-bordered {
      border: none; }
    td {
      border-left: none;
      border-right: none;
      font-size: $text-12; } }
  &-description {
    table {
      margin: 30px 0;
      width: 100%;
      td {
        padding: 10px;
        font-size: 14px;
        border: $thin $theme; } } }
  &-cols {
    h3 {
      font-size: $text-16;
      color: $theme;
      text-transform: uppercase;
      margin-bottom: 15px; }
    h4 {
      margin: 15px 0 10px;
      font-size: $text-14;
      text-transform: uppercase;
      color: $black; }
    table {
      width: 100%;
      td {
        padding: 5px;
        border-bottom: $thin $grey; } }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        position: relative;
        padding-left: 10px;
        margin-left: 10px;
        line-height: 1.8;
        &:before {
          content: '\f0da';
          font-family: 'FontAwesome';
          color: $theme;
          position: absolute;
          top: 0;
          left: 0; } } } }
  &-tabschars {
    h3 {
      font-size: $text-16;
      color: $theme;
      text-transform: uppercase;
      margin-bottom: 15px; }
    table {
      width: 100%;
      tr:first-child {
        background-color: $theme;
        color: $white;
        font-weight: 500; }
      tr:nth-child(even) {
        background-color: $super-grey; }
      td,
      th {
        padding: 10px;
        font-size: $text-14; } } } }

.prod-tab-wrapper {
  margin: 30px 0;
  .nav-tabs>li>a {
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
    .fa {
      margin-right: 7px; } }
  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:focus,
  .nav-tabs>li.active>a:hover {
    background-color: transparent;
    border-bottom: $thin $white; }
  .tab-content {
    padding: 20px 10px; } }

.rel-prod {
  text-align: center;
  border: $thin $border;
  padding: 15px;
  img {
    display: inline-block; }
  .price {
    font-weight: 600;
    color: $theme; } }


